<template>
  <PageHeaderLayout v-show='this.$route.matched.length===2'>

    <div class='main-page-content'>
      <el-card class='box-card'>
        <div style='text-align: center;font-weight: bold;font-size: 24px;margin-bottom: 10px;border-radius:10px '>
          <el-button :loading='loading' icon='el-icon-delete' style='float: left' type='text' @click='forceClear'>
            清空
          </el-button>
          <span>
                 实时在线人员名单(<span style='color: #ff3176'>{{ lineArray.length }}</span>)
            </span>
          <el-button :loading='loading' icon='el-icon-refresh' type='text' @click='refreshOnline'>刷新</el-button>
          <el-checkbox style='float: right' v-model='subStatus' @change='subEvent'>订阅：上下线通知</el-checkbox>
        </div>
      </el-card>
      <br>
      <div style='width: 100%;'>
        <div class='online-box'>
          <div v-for='(item,index) in lineArray' :key='index' class='user_box' @click.stop='jumpToDetail(item)'>
            <transition name='el-fade-in-linear'>
              <el-tooltip class='item' effect='dark' placement='top'>
                <div slot='content'>
                  用户：{{ item.nickname }}<br> 浏览器:{{ item.browser }}<br>设备：{{
                    item.device
                  }}<br>系统：{{ item.platform }}<br>
                  IP：{{ item.ip }} <br>在线时间：{{ item.login_at || '-' }}
                </div>
                <div class='users donghua'>
                  <div class='user_avatar'>
                    <el-image :preview-src-list='[item.avatar||avatar]' :src='item.avatar||avatar'
                              height='50px'
                              style='border-radius: 50%;margin: 0 auto;display: block;border: 1px solid #E4E7ED;cursor: pointer'
                              width='50px' />
                  </div>
                  <div class='user_info'>
                                        <span
                                          style='white-space: nowrap;overflow-x: hidden;text-overflow:ellipsis;display: block;text-align: center'>
                                            <el-link class='el-icon-close' style='width: 10px;margin-right: 2px'
                                                     type='primary' @click.stop='handleOutline'>
                                        </el-link> {{
                                            item.nickname || '未知'
                                          }}</span>
                  </div>
                </div>
              </el-tooltip>
            </transition>
          </div>
        </div>
        <div>
          <el-divider content-position='left'>
            <el-link @click='getOnEvents'>监听的事件</el-link>
          </el-divider>
          <el-tag v-for='(e,eventName) in eventList' :key='eventName' style='margin: 2px'>
            {{ eventName }}
          </el-tag>
        </div>
        <div>
          <el-button type='text' @click='clearTmp'>清空</el-button>
        </div>
        <div style='display: flex'>
          <div class='show-staff'>
            <div v-for='(staff,index) in online' :key='index'>
              <template v-if='!showStaff ||index===showStaff'>
                {{ staff.nickname }} 第【{{ staff.count || 0 }}】次上线 (时间：{{ staff.sec || 0 }} s)
                <el-button type='text' @click='onlyShow(staff,index)'>仅看Ta</el-button>
              </template>

            </div>
          </div>
          <div class='show-staff'>
            <div v-for='(staff,index) in outline' :key='index'>
              <!--              {{ staff.nickname }} 第【{{ staff.count || 0 }}】次下线-->
              <template v-if='!showStaff ||index===showStaff'>
                {{ staff.nickname }} 第【{{ staff.count || 0 }}】次下线 (时间：{{ staff.sec || 0 }} s)
                <el-button type='text' @click='onlyShow(staff,index)'>仅看Ta</el-button>
              </template>
            </div>

          </div>
        </div>
      </div>
    </div>
  </PageHeaderLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import avatar from '@/assets/avatar.png'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'OnLine',
  components: {
    PageHeaderLayout
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      avatar: avatar,
      lineArray: [],
      token: '',
      loading: false,
      subStatus: true,
      eventList: {},//监听的事件列表
      showStaff: '',
      online: {},
      outline: {}
    }
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      //订阅
      this.listenerWsEvent()
    }, 1000)
  },
  methods: {
    clearTmp() {
      this.online = {}
      this.outline = {}
      this.showStaff = ''
    },
    onlyShow(row, staffId) {
      this.showStaff = staffId
      this.$notify.success(`仅看用户【${row.nickname || ''}】`)
    },
    //订阅websocket事件
    subWsEvent() {
      //在线用户列表
      this.subOnlineList()
      //用户上下先通知
      this.subEvent()
    },
    //监听websocket事件
    listenerWsEvent() {
      if (this.$globalWs.ws) {
        // 事件监听-在线用户列表
        this.$globalWs.onEvt('ev_online_list', (msg, data) => {
          if (msg === 'success') {
            //在线用列表
            this.lineArray = data || []
          }
        })
      }

      this.subWsEvent()
    },
    subEvent() {
      if (!this.$globalWs.ws) {
        this.$notify.warning('WebSocket服务尚未连接成功')
        return false
      }
      //订阅上下线通知事件
      if (this.subStatus) {
        //订阅事件
        this.$globalWs.subEvt('subscribe_logout')
        this.$globalWs.subEvt('subscribe_login')
        // 事件监听-用户下线
        this.$globalWs.onEvt('ev_logout', (msg, data) => {
          if (msg === 'success') {
            let currDate = new Date()
            // this.$notify.success(`用户[${data.nickname || '--'}]下线`)
            let staffId = data.id
            if (!this.outline[staffId])
              this.outline[staffId] = { nickname: data.nickname, count: 1, time: currDate.getTime() }
            else {
              this.outline[staffId]['count'] += 1
              this.outline[staffId]['sec'] = (currDate.getTime() - this.outline[staffId]['time'])
            }


            const index = this.lineArray.findIndex((value => value.id === data.id))
            if (index > -1)
              this.lineArray.splice(index, 1)
          }
        })
        // 事件监听-用户上线
        this.$globalWs.onEvt('ev_login', (msg, data) => {
          if (msg === 'success') {
            let currDate = new Date()
            let staffId = data.id
            if (!this.online[staffId])
              this.online[staffId] = { nickname: data.nickname, count: 1, time: currDate.getTime() }
            else {
              this.outline[staffId]['count'] += 1
              this.outline[staffId]['sec'] = (currDate.getTime() - this.outline[staffId]['time'])/1000
            }
            // this.$notify.success(`用户[${data.nickname || '--'}]上线了！！！`)
            //在线用列表
            const index = this.lineArray.findIndex((value => value.id === data.id))
            if (index === -1)
              this.lineArray.unshift(data)
          }
        })
      } else {
        //订阅事件
        this.$globalWs.subEvt('cancel_subscribe_logout')
        this.$globalWs.subEvt('cancel_subscribe_login')

        this.$globalWs.rmEvt('ev_login')
        this.$globalWs.rmEvt('ev_logout')
      }
    },
    //订阅在线用户列表
    subOnlineList() {
      this.$globalWs.subEvt('get_online_users')
    },
    eventOnlineList() {
      // 绑定事件-新消息接收处理
      this.$globalWs.onEvt('ev_online_list', (msg, data) => {
        if (msg === 'success') {
          //在线用列表
          this.lineArray = data || []
        }
      })
    },
    getNotifyMessage(data) {
      console.log('======= 消息接收处理======')
      console.log(data)
    },
    forceClear() {
      this.lineArray = []
      if (this.$globalWs) {
        this.$globalWs.sendMsg({ event: 'force_clear_online' })
      }
    },
    refreshOnline() {
      // this.loading = true
      //清空在线状态
      this.lineArray = []
      if (this.$globalWs) {
        this.$globalWs.sendMsg({ event: 'clear_online' })
      }
      setTimeout(() => {
        this.subOnlineList()
      }, 500)
    },
    // 返回角色列表
    returnList() {
      this.$router.push(this.$route.matched[1].path)
    },
    jumpToPage(pageName, params) {
      this.$router.push({
        name: pageName,
        params: params
      })
      // const { href } = this.$router.resolve({
      //   name: pageName,
      //   params: params
      // })
      // window.open(href, '_blank')
    },
    jumpToDetail(row) {
      this.jumpToPage('UserDetail', { user_id: row.id })

    },
    handleOutline() {
      this.$notify.info('强制下线功能开发中....')
    },
    getOnEvents() {
      this.eventList = this.$globalWs.getOnEvents()
      // console.log(this.eventList)
    }
  },
  beforeDestroy() {
    this.subStatus = false
    //用户上下先通知
    this.subEvent()
  }

}
</script>

<style scoped>
.online-box {
  width: 98%;
  border: #ff3176 1px dashed;
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  text-align: left;
  overflow: auto;
  min-height: 600px;
  /*overflow-y: scroll;*/
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
}

.user_box {
  width: 100px;
  float: left;
}


.users {
  margin: 0 auto;
  display: block;
  cursor: pointer;
}

.user_avatar {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
}

.user_info {
  width: 100%;
  height: 30px;
  margin-top: 10px;
}

.user_text {
  display: block;
  text-align: center;
  font-size: 12px;
  /*margin: 1px 0;*/
  color: black;
  overflow: hidden;
}

.show-staff {
  height: 500px;
  overflow-y: scroll;
  width: 500px;
  margin: 5px;
  border: #e97ca1 1px dashed;
  padding: 10px;
  border-radius: 5px;
}

/* 定义关键帧 */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  /*100% {*/
  /*  opacity: 1;*/
  /*  transform: translateY(-20px);*/
  /*}*/
}

/* 应用动画到元素 */
.donghua {
  animation: fadeInOut 2s 1; /* 持续时间，次数(n或者infinite)，效果，填充模式，是否暂停等 */
}

</style>

